@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: MC, system-ui, sans-serif;
  }

  body {
    @apply bg-black h-screen select-none font-light;
    overflow: hidden;
    color: #141413;
  }
}

@font-face {
  font-family: 'MC';
  src: url('./assets/fonts/MarkForMCNrwO-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'MC';
  src: url('./assets/fonts/MarkForMCNrwO-BoldIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'MC';
  src: url('./assets/fonts/MarkForMCNrwO-Light.ttf') format('truetype');
  font-weight: light;
}

@font-face {
  font-family: 'MC';
  src: url('./assets/fonts/MarkForMCNrwO-LightIt.ttf') format('truetype');
  font-weight: light;
  font-style: italic;
}

#root {
  @apply h-full;
}

strong {
  @apply font-bold;
}

.arrow-left {
  @apply relative;
}

.arrow-left::after {
  @apply block absolute -left-3 w-6 h-6 bg-white rotate-45;
  content: '';
}

.left-border::before {
  @apply w-14 h-full block bg-orange;
  content: '';
}

/* Animated circle */
.circle {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: stroke 2s ease-in-out forwards;
}

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

/* Modals */
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.modal-overlay {
  @apply bg-black/60 fixed top-0 left-0 right-0 bottom-0;
}

.disclosure-modal {
  @apply absolute z-20 outline-none shadow-2xl rounded-2xl py-10 px-8 bg-eco-white top-auto left-[var(--layout-padding-x)] right-[var(--layout-padding-x)];
  bottom: calc(var(--footer-height) + 1rem);
}

.disclosure-modal p {
  @apply my-8 text-black text-lg;
}

.payment-modal .payment {
  --w: 30rem;
  --h: 20rem;
  @apply absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%] z-50
    outline-none text-black text-4xl text-center rounded-2xl py-16 px-6 bg-eco-white
    flex flex-col justify-center items-center shadow-2xl;
  width: var(--w);
  min-height: var(--h);
}

.payment-modal .payment-disclosure {
  @apply absolute z-20 outline-none text-black text-lg rounded-2xl py-10 px-8 bg-eco-white shadow-2xl
  top-auto left-[var(--layout-padding-x)] right-[var(--layout-padding-x)];
  bottom: calc(var(--footer-height) + 1rem);
}

:root {
  --layout-padding-x: 57px;
  --header-footer-padding-y: 48px;
  --content-padding-top: 155px;
  --footer-height: 180px;
}

.layout-top-padding {
  @apply pt-[110px];
}

.layout-left-padding {
  padding-left: var(--layout-padding-x);
}

.layout-right-padding {
  padding-right: var(--layout-padding-x);
}

.layout-bottom-padding {
  @apply pb-8;
}

.header-padding-y {
  padding-top: var(--header-footer-padding-y);
  padding-bottom: var(--header-footer-padding-y);
}

.footer-padding-y {
  padding-top: var(--header-footer-padding-y);
  padding-bottom: var(--header-footer-padding-y);
}